import React, { useState, Fragment } from 'react'

import Select from 'react-select'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import AddClassesTable from 'components/admin/AddClassesTable'
// import {DataReader} from 'components/admin/ParaPhrase'

const AddClassesPage = () => {
	const columns = [
		{
			text: 'Id',
			dataField: 'id',
			sort: true,
		},
		{
			text: 'Name',
			dataField: 'name',
			sort: true,
		},
		{
			text: 'Year',
			dataField: 'year',
			sort: true,
		},

		{
			text: 'Status',
			dataField: 'status',
			sort: true,
		},
		{
			text: 'Status',
			dataField: 'statusIcon',
			sort: true,
			headerAlign: 'center',
			align: 'center',
			formatter: (e) => {
				// console.log(e)
				const get_icon = (e) => {
					if (e.data === 'success') {
						return (
							<>
								<i className='fa fa-check text-success'></i>
							</>
						)
					} else {
						return <i className='fa fa-times text-danger'></i>
					}
				}

				return (
					<Fragment>
						<button
							onClick={async () => {
								console.log({ uid: e.uid })
							}}
							className='btn btn-primary'
						>
							Delete
						</button>
						{get_icon(e)}
					</Fragment>
				)
			},
		},
	]
	const [tableCSV] = useState([])
	const [tableData] = useState([
		{ id: 1, name: 'Blockchain Technology', year: 'FY', status: 'success', statusIcon: 'success' },
		{ id: 2, name: 'Deep Learning', year: 'SY', status: 'success', statusIcon: 'success' },
		{ id: 3, name: 'Computer Networks', year: 'TY', status: 'success', statusIcon: 'success' },
		{ id: 4, name: 'Computer Graphics', year: 'TY', status: 'fail', statusIcon: 'fail' },
	])
	const [categories] = useState([])
	const [subCategories] = useState([])
	const [show, setShow] = useState(false)

	const handleClose = () => {
		setShow(false)
	}
	const handleShow = () => setShow(true)

	const [modalData, setModalData] = useState({
		category: '',
		subCategory: '',
		course_name: '',
		description: '',
	})
	return (
		<Fragment>
			<Fragment>
				<div className='row mt-4 mx-2'>
					<div className='col-3'>
						<h2>Add Courses</h2>
					</div>
					<div className='col-9 px-2'>
						<div className='float-right'>
							<button onClick={() => window.open('/course_sample_updated.csv')} className='btn btn-primary'>
								<i className='fa fa-download px-2 '></i>
								<span className='pl-2'>Download Sample File</span>
							</button>
						</div>
						<div className='float-right px-2'>
							<button className='btn btn-primary' onClick={handleShow}>
								<i className='fa fa-plus px-2'></i>
								<span className='pl-2'>Add Course</span>
							</button>
						</div>
					</div>
				</div>
				<div className='row mt-4 mx-2'>
					<div className='col-12'>
						<div className='card'>
							<form>
								<div className='card-header bg-white'></div>
								<div className='card-body'>
									<AddClassesTable data={tableData} columns={columns} />
								</div>
							</form>
						</div>
					</div>
				</div>
				<Modal show={show} onHide={handleClose} backdrop='static' keyboard={false} size='lg' centered>
					<Modal.Header closeButton>
						<Modal.Title>Add Course</Modal.Title>
					</Modal.Header>

					<Modal.Body>
						<Form>
							<Row>
								<Col lg={6}>
									<Form.Group controlId='txtTeacherName'>
										<Form.Label>Category</Form.Label>
										<Select
											options={categories}
											onChange={(e) => {
												setModalData({
													...modalData,
													category: e.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
								<Col lg={6}>
									<Form.Group controlId='txtTeacherName'>
										<Form.Label>Sub-Category</Form.Label>
										<Select
											options={subCategories}
											onChange={(e) => {
												setModalData({
													...modalData,
													subCategory: e.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col lg={12}>
									<Form.Group controlId='txtcourse_name'>
										<Form.Label>Course Name</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Course Name'
											onChange={(e) => {
												setModalData({
													...modalData,
													course_name: e.target.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
							<Row>
								<Col lg={6}>
									<Form.Group controlId='txtdescription'>
										<Form.Label>Course Description</Form.Label>
										<Form.Control
											type='text'
											placeholder='Enter Course Description'
											onChange={(e) => {
												setModalData({
													...modalData,
													description: e?.target?.value,
												})
											}}
										/>
									</Form.Group>
								</Col>
							</Row>
						</Form>
					</Modal.Body>

					<Modal.Footer>
						<Button variant='secondary' onClick={handleClose}>
							Close
						</Button>
						<Button variant='primary'>Save changes</Button>
					</Modal.Footer>
				</Modal>
				<br />
				<br />
				<br />
				<br />
				<h1>Add Courses CSV</h1>

				<table>
					{tableCSV.length > 0 && (
						<AddClassesTable
							data={tableCSV}
							columns={[
								{
									text: 'course_name',
									dataField: 'course_name',
									sort: true,
								},
								{
									text: 'category',
									dataField: 'category',
									sort: true,
								},
								{
									text: 'description',
									dataField: 'description',
									sort: true,
								},
							]}
						/>
					)}

					<button>Submit</button>
				</table>
			</Fragment>
		</Fragment>
	)
}

export default AddClassesPage
